module.exports = {
  siteTitle: 'Phuma Phambili Engineering', // <title>
  manifestName: 'Grayscale',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#1e2836',
  manifestThemeColor: '#1e2836',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/phumaphambili.com`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'PHUMA PHAMBILI ENGINEERING',
  subHeading: 'MOVING AHEAD',

  email: 'info@phumaphambili.com',
  phone: '+27 (0)73 145 2177',
  address: '9 Babbit Avenue, Dersely Park, Springs, Gauteng, 1559',
  help: 'Help',
  privacyPolicy: 'Privacy Policy',
};
